import APIConstant from 'constants/ApiConstant';
import { makeAutoObservable, runInAction } from 'mobx';
import { deleteRequest, getRequest, postRequest } from 'utils/apiUtils';
import { getValidDataFromResponse } from 'utils/utilities';

class MhraStore {
	isSubmittingMhra = false;
	isFetchingMhra = false;
	isDeletingMhra = false;
	mhraToolboxData = [];
	rowId = '';
	mhraData = [];

	constructor() {
		makeAutoObservable(this);
	}

	submitNewMhra = body => {
		return new Promise(async (resolve, reject) => {
			try {
				const { response } = await postRequest({ url: APIConstant.INSERT_MHRA, payload: body });
				const { result, error } = getValidDataFromResponse({ response });

				if (!error) {
					resolve();
				} else {
					throw new Error(`Something went wrong`);
				}
			} catch (error) {
				reject(error.message);
			}
		});
	};

	getAllMhra = () => {
		return new Promise(async (resolve, reject) => {
			try {
				const { response } = await getRequest({ url: APIConstant.GET_ALL_MHRA });
				const { result, error } = getValidDataFromResponse({ response, isRequired: false });
				console.log('🚀 ~ MhraStore ~ returnnewPromise ~ result:', result);

				if (!error) {
					resolve();
					this.setMhraList(result);
				} else {
					throw new Error(`Something went wrong`);
				}
			} catch (error) {
				this.setMhraList();
				reject(error.message);
			}
		});
	};

	deleteMhra = (mhraId, row) => {
		return new Promise(async (resolve, reject) => {
			try {
				const { response } = await deleteRequest({ url: `${APIConstant.DELETE_MHRA}/${mhraId}` });
				const { result, error } = getValidDataFromResponse({ response });

				if (!error) {
					resolve();
					this.getAllSop();
				} else {
					throw new Error(`Something went wrong`);
				}
			} catch (error) {
				console.log('🚀 ~ MhraStore ~ returnnewPromise ~ error:', error);
				reject();
			}
		});
	};

	getMhraWithSubEducation = () => {
		return new Promise(async (resolve, reject) => {
			try {
				const { response } = await getRequest({ url: APIConstant.GET_MHRA_SUB_EDUCATION });
				const { result, error } = getValidDataFromResponse({ response, isRequired: false });

				if (!error) {
					resolve();
					this.setMhraToolboxList(result);
				} else {
					throw new Error(`Something went wrong`);
				}
			} catch (error) {
				this.setSopList();
				reject(error.message);
			}
		});
	};

	setIsSubmittingMhra = (value = false) => {
		runInAction(() => {
			this.isSubmittingMhra = value;
		});
	};

	setIsDeletingMhra = (value = false, rowId = '') => {
		runInAction(() => {
			this.isDeletingMhra = value;
			this.rowId = rowId;
		});
	};

	setMhraList = data => {
		runInAction(() => {
			this.mhraData = data;
		});
	};

	setMhraToolboxList = (data = []) => {
		runInAction(() => {
			this.mhraToolboxData = data;
		});
	};

	setIsFetchingMhra = (value = false) => {
		runInAction(() => {
			this.isFetchingMhra = value;
		});
	};
}

export default MhraStore;
